<template>
  <v-main>
    <v-container class="body">
      <h1 class="display-1">About</h1>
      <div class="text-center">
        <div>
          <peeps-button class="mt-2 ml-2"></peeps-button>
          <nitrate-button class="mt-2 ml-2"></nitrate-button>
          <privacy-button class="mt-2 ml-2"></privacy-button>
        </div>
      </div>
    </v-container>
    <v-footer>
      <div v-if="buildDate" class="build-date">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span color="primary" dark v-bind="attrs" v-on="on">
              Updated {{ fromNow(buildDate) }}
            </span>
          </template>
          <span>{{ format(buildDate, 'llll') }}</span>
        </v-tooltip>
      </div>
      <v-col class="text-center">
        <p>
          Copyright © 2021 by World Wide Web Works LLC. All rights reserved, and
          no part of this website may be reproduced, stored in a retrieval
          system, or transmitted in any form or by any means-–electronic,
          mechanical, photocopying, recording, or otherwise–-without prior
          written consent of the copyright owner.
        </p>
      </v-col>
    </v-footer>
  </v-main>
</template>

<script>
import Moment from 'moment'
export default {
  metaInfo: {
    title: 'About'
  },
  computed: {
    buildDate() {
      if (process.env.VUE_APP_BUILD_DATE) {
        let buildDate = new Date(process.env.VUE_APP_BUILD_DATE)
        if (buildDate == 'Invalid Date') {
          return null
        } else {
          return buildDate
        }
      }
      return null
    }
  },
  methods: {
    format(date, fmt) {
      return Moment(date).format(fmt)
    },
    fromNow(date) {
      return Moment(date).fromNow()
    }
  }
}
</script>

<style scoped>
.build-date {
  top: -2rem;
  right: 2rem;
  position: absolute;
  color: rgb(114, 107, 107);
  z-index: 999;
}
.body {
  min-height: 90%;
}
</style>
